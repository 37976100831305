import { SiJavascript, SiTypescript, SiHtml5, SiCss3, SiNodedotjs, SiReact, SiNextdotjs, SiTailwindcss, SiBootstrap, SiExpress, SiVercel, SiGithub } from "react-icons/si"
import { TbFlower } from "react-icons/tb"

/* You can go to http://react-icons.github.io/react-icons for explore and add icons. */

export default {

    data: [
        {
            name: "JavaScript",
            recentlyIKnow: 100,
            icon: (<SiJavascript color="YELLOW" size="30px"/>)
        },
        {
            name: "TypeScript",
            recentlyIKnow: 90,
            icon: (<SiTypescript color="AQUA" size="30px"/>)
        },
        {
            name: "Node.js",
            recentlyIKnow: 100,
            icon: (<SiNodedotjs color="#68a063" size="30px"/>)
        },
        {
            name: "Github",
            recentlyIKnow: 100,
            icon: (<SiGithub color="WHITE" size="30px"/>)
        }
    ]

}