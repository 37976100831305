export default {

    active: [

        {
            name: "XRongo Community",
            description: "My Discord community server.",
            image: "./image/X_LOGO_resized.png",
            role: "Founder",
            link: "https://discord.gg/ckz4fwTxpA"
        }

    ]

}