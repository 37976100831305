export default {

    userId: "592442056115486723",
    github: "XRongo",
    description: "My name is XRongo. I am 17 years old Turkish student. I interested in Software. I can develop Discord bot for now.",
    about: {
        description: "Hello. My name is <span class='text-primary'>XRongo</span>. I am <span class='text-primary'>17 years</span> old. I am a <span class='text-primary'>4th year high school student</span> and I interested in <span class='text-primary'>Software</span>. I've been coding <span class='text-primary'>since 2022</span> and I want to improve myself in this field.",
        liveIn: "Antalya, Alanya",
        birthday: "01 September, 2007",
        gender: "Man"
    }

}